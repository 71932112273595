import AppFooter from "@crema/core/AppLayout/components/AppFooter";
import AppHeader from "@crema/core/AppLayout/Default/AppHeader";
import { Layout, Segmented, Space } from "antd";
import { useState } from "react";

export const TosPage = () => {
  const [language, setLanguage] = useState("Tiếng Việt");
  return (
    <>
      <Layout className="app-layout-main">
        <AppHeader onToggleSidebar={() => ""} />
      </Layout>

      <div
        className="container"
        style={{
          minHeight: "70vh",
          width: "100%",
          height: "100%",
          marginTop: "2em",
        }}
      >
        <Space
          style={{
            width: "100%",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <Segmented
            onChange={(e) => setLanguage(e as string)}
            options={["Tiếng Việt", "English"]}
          />
        </Space>
        <div style={{ fontSize: "15px" }} hidden={language != "Tiếng Việt"}>
          <h2>ĐIỀU KHOẢN DỊCH VỤ</h2>
          <p>
            Bằng việc sử dụng dịch vụ của NHA TRANG EXPRESS, khách hàng đồng ý
            với các điều khoản sau:
          </p>
          <ul>
            <li>
              NHA TRANG EXPRESS và khách hàng tôn trọng và tuân thủ nghiêm ngặt
              các quy định của Việt Nam và Hoa Kỳ về vận chuyển quốc tế và nội
              địa.
            </li>
            <li>
              Khách hàng sẽ không vận chuyển bất kỳ sản phẩm bất hợp pháp hoặc
              hàng hóa bị cấm theo quy định của pháp luật Việt Nam và Hoa Kỳ.
              NHA TRANG EXPRESS sẽ không chịu trách nhiệm đối với bất kỳ mặt
              hàng bất hợp pháp nào bị tịch thu và tiêu hủy bởi chính quyền Việt
              Nam và Hoa Kỳ.
            </li>
            <li>
              Khách hàng sẽ mô tả trung thực, rõ ràng tất cả hàng hóa và giá trị
              thực của hàng hóa trong kiện hàng của khách hàng. Những hàng hóa
              này bao gồm nhưng không giới hạn ở đồ điện tử (điện thoại, máy
              tính bảng, máy tính, thiết bị gia dụng, v.v.), các mặt hàng có giá
              trị (trang sức, quần áo sang trọng, v.v.) và hàng hóa nguy hiểm
              (dễ cháy, nổ, nguy cơ sinh học, v.v.) và sẽ chịu trách nhiệm về
              bất kỳ các khoản thuế bổ sung, phí hoặc hình phạt phát sinh do
              không khai báo chính xác.
            </li>
            <li>
              NHA TRANG EXPRESS có quyền kiểm tra kiện hàng và yêu cầu cung cấp
              bằng chứng về giá trị thực của bất kỳ kiện hàng nào.
            </li>
            <li>
              NHA TRANG EXPRESS có quyền xác minh và nắm giữ thông tin của khách
              hàng để phục vụ cho việc vận chuyển hàng hóa. NHA TRANG EXPRESS sẽ
              bảo vệ dữ liệu cá nhân và quyền riêng tư của khách hàng và sẽ
              không chia sẻ thông tin này cho bất kỳ bên nào ngoài những bên
              liên quan đến việc hỗ trợ vận chuyển gói hàng.
            </li>
            <li>
              Mỗi gói gửi đến Việt Nam sẽ bao gồm bảo hiểm cơ bản của chúng tôi
              tối đa là $100. Nếu khách hàng muốn mua bảo hiểm đầy đủ cho các
              gói của mình, vui lòng hỏi nhân viên của chúng tôi để biết các lựa
              chọn bảo hiểm.
            </li>
            <li>
              Trong trường hợp hy hữu và đáng tiếc xảy ra mất mát, hư hỏng,
              khách hàng sẽ thông báo cho NHA TRANG EXPRESS trong vòng 3 ngày kể
              từ ngày nhận được hàng. Khách hàng cũng cần cung cấp bằng chứng về
              việc mất mát hoặc hư hỏng, chẳng hạn như hình ảnh và video trực
              tiếp về tình trạng của gói hàng khi nhận. Những bằng chứng này sẽ
              được yêu cầu để xử lý khiếu nại của khách hàng.
            </li>
          </ul>
        </div>

        <div style={{ fontSize: "15px" }} hidden={language != "English"}>
          <h2>TERMS OF SERVICE</h2>
          <p>
            By using NHA TRANG EXPRESS services, customers agree to the
            following terms:
          </p>
          <ul>
            <li>
              NHA TRANG EXPRESS and our customers respect and strictly adhere to
              Vietnamese and US regulations regarding international and domestic
              shipping.
            </li>
            <li>
              Customers will not ship any illegal products or goods forbidden by
              Vietnamese and US laws. NHA TRANG EXPRESS will not be responsible
              for any illegal items confiscated and destroyed by the Vietnamese
              and US authorities.
            </li>
            <li>
              Customers will honestly and clearly describe all the goods and
              their true values in customers’ packages. These goods include but
              not limited to electronics (phones, tablets, computers,
              appliances, etc), valuable items (jewelries, luxury apparels,
              etc), and dangerous goods (flammables, explosives, biological
              hazards, etc) and will be responsible for any extra duties, fees
              or penalties generated from not declaring accurately.
            </li>
            <li>
              NHA TRANG EXPRESS has the rights to inspect the package and to ask
              for proof of any item’s true value.
            </li>
            <li>
              NHA TRANG EXPRESS has the rights to verify and hold customers’
              information for the purpose of transporting the goods. NHA TRANG
              EXPRESS will protect customers’ personal data and privacy and will
              not share this information to any party other than those involved
              in facilitating the package’s transport.
            </li>
            <li>
              Every package sent to Vietnam will include our basic coverage of
              $100 maximum. If customers wish to purchase full coverage for
              their packages, please ask our staff for insurance options.
            </li>
            <li>
              In the rare and unfortunate event of loss or damage, customers
              will inform NHA TRANG EXPRESS within 3 days of receiving their
              packages. Customers also need to provide proof of the loss or
              damage such as immediate pictures and videos of the package’s
              condition upon receipt. These proofs will be required to process
              customers’ claims.
            </li>
          </ul>
        </div>
      </div>

      <AppFooter />
    </>
  );
};
