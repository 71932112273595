import AppContextProvider from "@crema/utility/AppContextProvider";
import AppThemeProvider from "@crema/utility/AppThemeProvider";
import { Spin } from "antd";
import "antd/dist/antd.less";
import { useRouter } from "hooks/useRouter";
import "mind-ar/dist/mindar-image.prod.js";
import { Suspense, useEffect, useState } from "react";
import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
import configureStore from "redux/store";
import { adminRoutes, routes } from "router";
import { settings } from "setting";
import { permissionStore } from "store/permissionStore";
import { userStore } from "store/userStore";
import "styles/AntDesign.scss";
import "styles/App.scss";
import { getToken } from "utils/auth";

const store = configureStore();

function App() {
  const element = useRoutes(routes);
  const [isLoaded, setIsLoaded] = useState(false);

  useRouter(true);

  //remove focus button
  document.addEventListener("click", function (e) {
    if (
      document.activeElement &&
      document.activeElement.toString() == "[object HTMLButtonElement]"
    ) {
      //@ts-ignore
      document.activeElement.blur();
    }
  });

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    try {
      if (getToken()) {
        await userStore.getProfile();
        permissionStore.accessRoutes = [...adminRoutes];
        if (settings.checkPermission && userStore.info.role) {
          await permissionStore.fetchPermissions(userStore.info.role.id);
          permissionStore.setAccessRoutes();
        }
      }
    } catch (error) {
      userStore.logout();
    } finally {
      setIsLoaded(true);
    }
  };

  return (
    <>
      <AppContextProvider>
        <Provider store={store}>
          <AppThemeProvider>
            {/* <ConfigProvider> */}
            {isLoaded ? (
              <div className="App">
                <Suspense fallback={<Spin />}>{element}</Suspense>
              </div>
            ) : (
              <div id="loading">
                <Spin spinning size="large" />
              </div>
            )}

            {/* <AppLayout /> */}
            {/* </ConfigProvider> */}
          </AppThemeProvider>
        </Provider>
      </AppContextProvider>
    </>
  );
}

export default App;
